<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>Carrot Farm Login</p>
            </div>
            <div class="con-wrap">
                <CarrotTitle title="Login"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <table class="table-row w-400px">
                            <colgroup>
                                <col width="150">
                                <col width="*">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>EMAIL</th>
                                    <td><input type="text" v-model="login.uid" class="w-200px float-right"></td>
                                </tr>
                                <tr>
                                    <th>PASSWORD</th>
                                    <td><input type="password" v-model="login.upw" class="w-200px float-right"></td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="txt-right">
                                        <button type="button" @click="login.doSubmit" class="btn-default dp-inblock">Login</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src 
import { reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import { useStore } from 'vuex'


export default {
    components: {
    },
    setup() {
        const router = useRouter();
        const toast = useToast();
        const store = useStore();

        const login = reactive({
            uid : "",
            upw : "",

            check_auth : () => {
                if(store.state.position!="") {
                    router.push({
                        name : 'main'
                    });
                } else {
                    router.push({
                        name : 'login'
                    });
                }
            },
            doSubmit : () => {
                let params = {
                    uid : login.uid,
                    upw : login.upw
                };

                if( !params.uid ){
                    toast.error("이메일을 입력하세요.");
                    return;
                }

                if( !params.upw ){
                    toast.error("비밀번호를 입력하세요.");
                    return;
                }

                axios.post("/rest/mypims/login", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : 'BusinessManagement-eLearningList'
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });
        onMounted(() => {
            // Mounted
            login.check_auth();
        });

        return {login};
    }
}
</script>

<style lang="scss" scoped>
</style>